import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
const NavigationBar3 = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const isAuthenticated = checkUserAuthentication();
    setIsLoggedIn(isAuthenticated);
  }, [isLoggedIn]);

  const checkUserAuthentication = () => {
    const token = window.localStorage.getItem("v3_user_id");
    return !!token;
  };

  useEffect(() => {
    const email_address = window.localStorage.getItem("v3_email_address");
    setEmail(email_address);
    const first_name = window.localStorage.getItem("v3_first_name");
    setFirstname(first_name);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/v3";
  };

  const path = window.location.pathname;

  return (
    <>
      
        <nav className="navbar navbar-expand-xl fixed-top">
          <div className="container">
            <a className="navbar-brand" href={`/v3`}>
              <img
                src={logo}
                border={0}
                className="desktop_logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fa-solid fa-bars f_30" />
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarToggler">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {isLoggedIn ? (
              <>
                <li className="nav-item">
                  <a className="nav-link active" href="/v3/event">Events</a>
                </li>
                <li className="nav-item dropdown navprofile">
                  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="prof_img">{firstname ? firstname.charAt(0).toUpperCase() : "A"}</span> {firstname}
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#" onClick={handleLogout}>Logout</a></li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/v3/login">Log In</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/v3/register">Sign Up</a>
                </li>
              </>
            )}
                
              </ul>
            </div>
          </div>
        </nav>
     
    </>
  );
};

export default NavigationBar3;
