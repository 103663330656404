import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Home() {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);

  return (
    <>
      <main>
        {/* shop area start */}
        <section className="tp-shop-area pt-100  homebanner">
          <div className="container-fluid">
            <div className="row align-items-center hometopbanner">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-0">
                {/* <div class="bannerimg"><img src="assets/img/bannerimg.png"/></div> */}
                <div
                  id="carouselsitesIndicators1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="bannerimg">
                        <img src="assets/img/bannerimg1.png" />
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="bannerimg">
                        <img src="assets/img/bannerimg7.png" />
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="bannerimg">
                        <img src="assets/img/bannerimg3.png" />
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="bannerimg">
                        <img src="assets/img/bannerimg4.png" />
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="bannerimg">
                        <img src="assets/img/bannerimg5.png" />
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="bannerimg">
                        <img src="assets/img/bannerimg8.png" />
                      </div>
                    </div>
                  </div>
                  {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselsitesIndicators1" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa-solid fa-chevron-left"></i></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselsitesIndicators1" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"><i class="fa-solid fa-chevron-right"></i></span>
                    <span class="visually-hidden">Next</span>
                  </button> */}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="bannertxt pt-50 pb-50">
                  <h2 className="mb-25">
                    Capturing memories with custom Photo Albums and Frames.
                  </h2>
                  Upload your photos, and we'll deliver them as albums and
                  frames to your liking
                  <div className="d-flex flex-row flex-wrap justify-content-center pt-25">
                    <a href="/register" className="btn btn-primary btn-lg">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* shop area end */}
        <section className="tp-shop-area">
          <div className="container">
            <div className="row text-center pt-50 pb-35">
              <h2 className="w-100 text-center">Photo Albums and Frames</h2>
              <p className="f_18">
                Here are some of our photo albums and frames
              </p>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/soft_cover_photoalbum.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">
                      Softcover Photobook Album
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/debossed_hardcover_photobook.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">
                      Debossed Hardcover Photobook
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/layflat_photobook_album.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">
                      Layflat Photobook Album
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img src="assets/img/sample4.jpg" alt border={0} />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">Canvas Prints</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/acrylic_photo_frame.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">Acrylic Photo Frame</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/premium_photo_with_frame.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">
                      Premium Photo with Frame
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/acrylic_photo_blocks.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">Acrylic Photo Blocks</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/desk_calendar_with_photo_frame.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">
                      Desk Calendar with Photo Frame
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img src="assets/img/LED_photo_frames.png" alt border={0} />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">LED Photo Frames</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="tp-product-item-2 mb-40">
                  <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                    <img
                      src="assets/img/LED_rotating_cube.png"
                      alt
                      border={0}
                    />
                  </div>
                  <div className="tp-product-content-2 px-2 py-3">
                    <h3 className="tp-product-title-2">LED Rotating Cube</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
