import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function EventEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const EventId = searchParams.get("id");
  const [event, setEvent] = useState(null);
  const [greetingdata, setGreeting] = useState([]);
  const [giftdata, setGift] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [selectedGreetingIds, setSelectedGreetingIds] = useState([]);
  const [selectedGiftIds, setSelectedGiftIds] = useState([]);
  const [isEditEventPath, setIsEditEventPath] = useState(false);

  useEffect(() => {
    // Get the pathname (e.g., "/v3/edit_event")
    const path = location.pathname; // e.g., "/v3/edit_event?id=66fe63ffe88cd88f20ab0c35"
    
    // Check if the path is for editing an event
    const editEventPath = path.split('?')[0]; // This will give you "/v3/edit_event"
    setIsEditEventPath(editEventPath.includes("edit_event"));

    
    console.log("Edit Event Path:", editEventPath);
  }, [location.pathname]);
  const handleSelectGreeting = (id) => {
    if (!Array.isArray(selectedGreetingIds)) return;
    setSelectedGreetingIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSelectGift = (id) => {
    if (!Array.isArray(selectedGiftIds)) return;
    setSelectedGiftIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  useEffect(() => {
    const pathname = location.pathname.startsWith("/v3");
    if (pathname) {
      document.body.classList.add("bgyellow");
    }
  }, []);

  useEffect(() => {
    if (EventId) {
      fetchEventData(EventId);
      fetchGreetingData();
      fetchGiftData();
    }
  }, [EventId]);

  const fetchEventData = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}event/list`, {
        params: { event_id: id },
      });

      if (res.data.status === "success") {
        const eventData = res.data.result;

        // Safely handling greeting_card
        setEvent(eventData);
        setSelectedGreetingIds(
          eventData.greeting_card && eventData.greeting_card._id
            ? [eventData.greeting_card._id]
            : []
        );

        // Safely handling gift array
        setSelectedGiftIds(
          eventData.gift && eventData.gift.length > 0
            ? eventData.gift.map((gift) => gift._id)
            : []
        );
      } else {
        // toast.error("Failed to fetch event data");
      }
    } catch (err) {
      console.error("Event fetch error:", err);
      // toast.error("Failed to fetch event data");
    }
  };

  const fetchGreetingData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}greeting/list`
      );
      if (res.data.status === "success") {
        setGreeting(res.data.result);
      } else {
        toast.error("Failed to fetch greeting data");
      }
    } catch (err) {
      console.error("Greeting fetch error:", err);
      toast.error("Failed to fetch greeting data");
    }
  };

  const fetchGiftData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}gift/list`);
      if (res.data.status === "success") {
        setGift(res.data.result);
      } else {
        toast.error("Failed to fetch gift data");
      }
    } catch (err) {
      console.error("Gift fetch error:", err);
      toast.error("Failed to fetch gift data");
    }
  };

  const handleUpdateEvent = async (e, action) => {
    e.preventDefault();

    // Validate required fields
    if (!event.event_name) {
      toast.error("Recipient Name is required.");
      return;
    }
    if (!event.phone_number) {
      toast.error("Phone number is required.");
      return;
    }
    if (!event.event_type) {
      toast.error("Event type is required.");
      return;
    }
    if (!event.address) {
      toast.error("Address is required.");
      return;
    }
    // if (!selectedGreetingIds.length) {
    //   toast.error("Please select a greeting card.");
    //   return;
    // }
    if (!selectedGiftIds.length) {
      toast.error("Please select at least one gift.");
      return;
    }

    const apiUrl = `${process.env.REACT_APP_APIURL}event/update`;
    const payload = {
      user_id: userId,
      event_id: EventId,
      event_name: event.event_name,
      phone_number: event.phone_number,
      event_type: event.event_type,
      address: event.address,
      greeting_card: selectedGreetingIds.toString(), // Use selected greeting card IDs
      greeting_notes: event.greeting_notes,
      gift: selectedGiftIds.toString(), // Use selected gift IDs
    };

    try {
      const response = await axios.post(apiUrl, payload);
      if (response.data.status === "success") {
        console.log("Event updated successfully!");
        navigate(action === "checkout" ? "/v3/checkout" : "/v3/event");
      } else {
        // toast.error("Failed to update event");
      }
    } catch (error) {
      console.error("Update event error:", error);
      // toast.error("Failed to update event");
    }
  };

  if (!event && !errorMessage) {
    return <div>Loading...</div>;
  }

  // if (errorMessage) {
  //   return toast.error(errorMessage);
  // }

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
  };

  return (
    <main>
      <section className="tp-login-area pt-120 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="tp-login-wrapper position-relative">
                <div className="row mb-30 align-items-center">
                  <div className="col-7 col-sm-7 col-lg-7 col-xl-7">
                  <h3 className="tp-login-title">{isEditEventPath ? "Edit Event" : "Add Event"}</h3>
                  </div>
                  <div className="col-5 col-sm-5 col-lg-5 col-xl-5">
                    <div className="txtalgn_rgt">
                      <a
                        href="/v3/event"
                        className="f_sz14 f_darkyellow crsr_pntr  txt_underline"
                      >
                        Back
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <form onSubmit={(e) => handleUpdateEvent(e, "save")}>
                    <div className="event_dtl">
                      <div className="event_dtldate">
                        {formatDate(event.event_date)}
                      </div>
                    </div>
                    <div className="flexdiv mt-25">
                      <h6 className="f_sz20 w-100">Recipient Details</h6>
                    </div>
                    <div className="row mt-15 mb-10">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="mb-10">
                          <input
                            type="text"
                            placeholder="Enter the Recipient Name"
                            value={event.event_name || ""}
                            onChange={(e) =>
                              setEvent({ ...event, event_name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="mb-10">
                          <input
                            type="text"
                            placeholder="Enter the Recipient Mobile Number"
                            value={event.phone_number || ""}
                            onChange={(e) => {
                              const { value } = e.target;
                              // Validate that the input is only 10 digits
                              if (/^\d{0,10}$/.test(value)) {
                                setEvent({ ...event, phone_number: value });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="mb-10">
                          <select
                            className="form-select"
                            value={event.event_type || ""}
                            onChange={(e) =>
                              setEvent({ ...event, event_type: e.target.value })
                            }
                          >
                            <option>Choose Occasion</option>
                            <option>Mother's Day</option>
                            <option>Birthday</option>
                            <option>Wedding</option>
                            <option>Anniversary</option>
                            <option>Easter</option>
                            <option>Holi</option>
                            <option>Christmas</option>
                            <option>Deepavali</option>
                            <option>New Year</option>
                            <option>Father's Day</option>
                            <option>Navarathri</option>
                            <option>Pongal</option>
                            <option>Onam</option>
                            <option>Janmashtami</option>
                            <option>Valentines Day</option>
                            <option>Ganesh Chaturthi</option>
                            <option>Baby Shower</option>
                            <option>Makar Sankranti</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-20">
                      <div className="col-12">
                        <textarea
                          className="txthgt150"
                          placeholder="Enter the Recipient Address"
                          value={event.address || ""}
                          onChange={(e) =>
                            setEvent({ ...event, address: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="brdr_btm w-100 mt-35 mb-35" />
                    <div className="flexdiv mb-3">
                      <h6 className="f_sz20 w-100 mb-10">Greeting Card</h6>
                      <div className="mb-15 w-100">
                        <input
                          type="text"
                          placeholder="Write a Personal Note"
                          value={event.greeting_notes || ""}
                          onChange={(e) =>
                            setEvent({
                              ...event,
                              greeting_notes: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="flexdiv">
                        <h6 className="mb-15">
                          Choose Greeting Card{" "}
                          <span
                            className="f_sz14 f_darkyellow crsr_pntr"
                            data-bs-toggle="modal"
                            data-bs-target="#modalgreetingcard"
                          >
                            (Click here to view full size)
                          </span>
                        </h6>

                        <div className="greetingcardgift_wrapper">
                          {greetingdata.map((data) => (
                            <div
                              className="greetingcardgift_item"
                              key={data.id}
                            >
                              <div className="greetingcardgift_img">
                                <img src={data.url} alt={data.name} />
                              </div>
                              <div className="greetingcardgift_price">
                                Rs.{data.price}.00
                              </div>
                              <div className="greetingcardgift_item_overlay">
                                <a
                                  className={`btn btn-yellow btn-sm ${
                                    selectedGreetingIds.includes(data._id)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleSelectGreeting(data._id)}
                                >
                                  {selectedGreetingIds.includes(data._id)
                                    ? "Selected"
                                    : "Choose"}
                                </a>
                              </div>
                              {selectedGreetingIds.includes(data._id) && (
                                <div className="greetingcardgift_selected">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="brdr_btm w-100 mt-35 mb-35" />
                    <div className="flexdiv mb-3">
                      <h6 className="f_sz20 w-100 mb-10">
                        Choose Gift{" "}
                        <span
                          className="f_sz14 f_darkyellow crsr_pntr"
                          data-bs-toggle="modal"
                          data-bs-target="#modalgift"
                        >
                          (Click here to view full size)
                        </span>
                      </h6>

                      <div className="flexdiv">
                        <div className="greetingcardgift_wrapper">
                          <div className="greetingcardgift_wrapper">
                            {giftdata.map((data) => (
                              <div
                                className="greetingcardgift_item"
                                key={data._id}
                              >
                                <div className="greetingcardgift_img">
                                  <img src={data.url} alt={data.name} />
                                </div>
                                <div className="greetingcardgift_price">
                                  Rs. {data.price}.00
                                </div>
                                <div className="greetingcardgift_item_overlay">
                                  <a
                                    className={`btn btn-yellow btn-sm ${
                                      selectedGiftIds.includes(data._id)
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() => handleSelectGift(data._id)}
                                  >
                                    {selectedGiftIds.includes(data._id)
                                      ? "Selected"
                                      : "Choose"}
                                  </a>
                                </div>
                                {selectedGiftIds.includes(data._id) && (
                                  <div className="greetingcardgift_selected">
                                    <i className="fa-solid fa-check"></i>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 d-flex flex-row flex-wrap text-center justify-content-center mt-55">
                      <button type="submit" className="btn btn-yellow m-2">
                        Submit and Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-yellow m-2"
                        onClick={(e) => handleUpdateEvent(e, "checkout")}
                      >
                        Submit and Proceed To Checkout
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      {/*Greeting Card Modal*/}
      <div
        className="modal fade"
        id="modalgreetingcard"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalgreetingcardLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalgreetingcardLabel">
                Greeting Card
              </h5>
            </div>
            <div className="modal-body">
              <div className="greetingcard_full">
                <div
                  id="carouselgreetingcardIndicators1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                >
                  <div className="carousel-inner">
                    {greetingdata.map((data, metaindex) => (
                      <div
                        key={metaindex}
                        className={`carousel-item ${
                          metaindex === 0 ? "active" : ""
                        }`}
                      >
                        <img src={data.url} border={0} />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselgreetingcardIndicators1"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselgreetingcardIndicators1"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Gift Modal*/}
      <div
        className="modal fade"
        id="modalgift"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalgiftLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalgiftLabel">
                Gift
              </h5>
            </div>
            <div className="modal-body">
              <div className="greetingcard_full">
                <div
                  id="carouselgiftIndicators1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                >
                  <div className="carousel-inner">
                    {giftdata.map((data, metaindex) => (
                      <div
                        key={metaindex}
                        className={`carousel-item ${
                          metaindex === 0 ? "active" : ""
                        }`}
                      >
                        <img src={data.url} border={0} />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselgiftIndicators1"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselgiftIndicators1"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default EventEdit;
