import React, { useState,useEffect } from "react";
import { useNavigate,useLocation} from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignupV3() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => { 
    const pathname = location.pathname.startsWith('/v3');
    if (pathname) {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");
    }
  }, []);
  const handleSignUp = (e) => {
    e.preventDefault();

    // Basic Validation
    if (!name.trim()) {
      toast.error("Name is required");
      return;
    }
    if (!email.trim()) {
      toast.error("Email is required");
      return;
    }
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return;
    }
    if (!phone.trim()) {
      toast.error("Phone number is required");
      return;
    }
    // Phone number validation (exactly 10 digits)
    if (!/^\d{10}$/.test(phone)) {
      toast.error("Phone number must be exactly 10 digits");
      return;
    }
    if (!password.trim()) {
      toast.error("Password is required");
      return;
    }
    // Password validation (minimum length)
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    // API Call
    axios
      .post(`${process.env.REACT_APP_APIURL}newuser/signup`, {
        email_address: email,
        password,
        first_name: name,
        phone_number: phone,
        ip_address:"220.158.156.212"
      })
      .then((res) => {
        if (res.data.status === "success") {
          window.localStorage.setItem("v3_email_address", email);
          window.localStorage.setItem("v3_user_id", res.data.result.user_id);
          window.localStorage.setItem("v3_first_name", name);
          toast.success("User registered successfully.");
          setIsLoggedIn(true);
          window.location.href = '/v3';
          // setTimeout(() => {
          //   navigate("/requestlist");
          // }, 2000);  // Redirect to dashboard or appropriate page
        } else {
          if (res.data.error && res.data.error.message) {
            toast.error(res.data.error.message);
          } else {
            toast.error("An error occurred during registration");
          }
        }
      })
      .catch((err) => {
        console.error("Registration error:", err);
        toast.error("An error occurred during registration");
      });
  };

  return (
    <main>
      <ToastContainer />
      <section className="tp-login-area pt-150 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-login-wrapper position-relative">
                <div className="tp-login-top text-center mb-30">
                  <h3 className="tp-login-title">Sign Up</h3>
                  <p>
                    Already have an account?{" "}
                    <span>
                      <a href="/v3/login" className="f_darkyellow">
                        Login
                      </a>
                    </span>
                  </p>
                </div>
                <div className="tp-login-option">
                  <form onSubmit={handleSignUp}>
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="name">Your Name</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="phone"
                            type="tel"
                            value={phone}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only digits and limit to 10 characters
                              if (/^\d*$/.test(value) && value.length <= 10) {
                                setPhone(value);
                              }
                            }}
                            maxLength={10} // Limit input to 10 digits
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="phone">Your Phone</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="tp_password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        {/* <div
                          className="tp-login-input-eye"
                          id="password-show-toggle"
                        >
                          <span id="open-eye" className="open-eye">
                            <i className="fa-solid fa-eye" />
                          </span>
                          <span id="close-eye" className="open-close">
                            <i className="fa-solid fa-eye-slash" />
                          </span>
                        </div> */}
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">Password</label>
                        </div>
                      </div>
                    </div>
                    <div className="tp-login-bottom">
                      <button type="submit" className="btn btn-yellow w-100">
                      Sign Up
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SignupV3;
