import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NavigationBar from "../layout/NavigationBar";
import Footer from "../layout/footer";
import Select from "react-select";
function ProductsList() {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedFrame, setSelectedFrame] = useState("");

  const handleSizeChange = (value) => {
    console.log(value.value)
    setSelectedSize(value);
  };

  const handleFrameChange = (value) => {
    setSelectedFrame(value);
  };

  const handleOrder = (productName) => {
    if (selectedSize && selectedFrame) {
      redirectToWhatsApp(productName, selectedSize, selectedFrame);
    }
  };

  const redirectToWhatsApp = (productName, selectedSize, selectedFrame) => {
    const message = `I would like to buy this Product.\nName: ${productName}\nSize: ${selectedSize.value}\nFrame: ${selectedFrame.value}`;
    const whatsappNumber = "+919342557147"; // Update with your WhatsApp number
    console.log(message);
  
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(process.env.REACT_APP_APIURL + "products/list")
      .then((res) => {
        if (res.data.status === "success") {
          setProductsData(res.data.result);
        }
      })
      .catch((err) => {
        console.error("Error fetching products:", err);
      });
  };

  return (
  <>

      <main>
        <section className="tp-shop-area pt-120 pb-120">
          <div className="container">
            <div className="row">
              {productsData.map((product, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                  key={index}
                >
                  <div className="tp-product-item-2 mb-40">
                    <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                      <img src={product.logo} alt={product.name} border={0} />
                    </div>
                    <div className="tp-product-content-2 px-2 py-3">
                      <h3 className="tp-product-title-2">{product.name}</h3>
                      <div className="tp-product-tag-2">
                        {product.description}
                        <br />
                      </div>
                      <div className="tp-product-price-wrapper-2">
                        <div className="d-flex justify-content-center mt-3">
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target={`#modalwantthis-${index}`}
                          >
                            Want This
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
     
      {/* product Modals */}
      {productsData.map((product, index) => (
        <div key={index}>
          <div
            className="modal fade"
            id={`modalwantthis-${index}`}
            tabIndex={-1}
            aria-labelledby={`modalwantthisLabel-${index}`}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close">
                  <i className="fa-regular fa-circle-xmark"></i></button>
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id={`modalwantthisLabel-${index}`}
                  >
                    {product.name}
                  </h5>
                </div>
                <div className="modal-body">
                <form>
                  <div className="flexdiv">
                   
                      <div className="mb-25 flexdiv">
                        <label className="col-12 col-sm-12 col-md-5 col-form-label f_bold">
                          Choose Size
                        </label>
                        <div className="col-12 col-sm-12 col-md-7">
                          <Select
                            options={product.size_list.map((size) => ({
                              value: size,
                              label: size,
                            }))}
                            value={selectedSize}
                            onChange={handleSizeChange}
                          />
                        </div>
                      </div>
                      <div className="mb-3 flexdiv">
                        <label className="col-12 col-sm-12 col-md-5 col-form-label f_bold">
                          Choose Frame
                        </label>
                        <div className="col-12 col-sm-12 col-md-7">
                          <Select
                            options={product.frame_list.map((frame) => ({
                              value: frame,
                              label: frame,
                            }))}
                            value={selectedFrame}
                            onChange={handleFrameChange}
                          />
                        </div>
                      </div>
                   
                  </div>
                  </form>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleOrder(product.name)}
                    disabled={!selectedSize || !selectedFrame}
                  >
                    Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ProductsList;
