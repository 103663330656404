import React, { Component, useContext, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import NavigationBarV3 from './v3NavigationBar';
function HeaderV3({ pathname }) {
    const navigate = useNavigate();
    // const [userLoc, setUserLoc] = useState(ReactSession.get("userlocation"));
    return (
        
        <header>
            <NavigationBarV3></NavigationBarV3>
        </header>
    );
}

export default HeaderV3;