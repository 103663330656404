import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
function Homev3() {
  const navigate = useNavigate();
  const [eventdata, setEventdata] = useState([]);
  const [user_id, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Track button state

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/v3") {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");
    }
  }, []);

  useEffect(() => {
    retrieveEventData();
  }, []);

  useEffect(() => {
    setIsButtonDisabled(eventdata.length === 0); // Update button state based on event data
  }, [eventdata]);

  function retrieveEventData() {
    console.log("hi");
    const storedEventData = localStorage.getItem("event_data");
    if (storedEventData) {
      const event_data = JSON.parse(storedEventData);
      setEventdata(event_data);
    }
  }

  const getShortMonthName = (month) => {
    const shortMonths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return shortMonths[month - 1];
  };
  const removeEvent = (id) => {
    // Filter out the event with the provided ID
    const updatedEvents = eventdata.filter((event) => event.id !== id);

    // Update the state
    setEventdata(updatedEvents);

    // Update local storage
    localStorage.setItem("event_data", JSON.stringify(updatedEvents));
  };
  const EventCreate = (e) => {
    e.preventDefault();
    if (isButtonDisabled) return; // Prevent action if button is disabled
    // Convert to an array of strings
    const eventIds = eventdata.map((event) => event.id);

    console.log(eventIds);
    axios
      .post(`${process.env.REACT_APP_APIURL}event/create`, {
        user_id: user_id,
        event_dates: eventIds.toString(),
      })
      .then((res) => {
        if (res.data.status === "success") {
          window.localStorage.setItem("event_data", []);
          toast.success("Event Created successfully");
          window.location.href = "/v3/event";
        } else {
          const errorMessage =
            res.data.error?.message || "An error occurred during login";
          toast.error(errorMessage);
        }
      })
      .catch((err) => {
        console.error("Login error:", err.response.data.error.message);
        toast.error(err.response.data.error.message);
      });
  };
  return (
    <>
      <main>
        {/* shop area start */}
        <section className="tp-shop-area pt-70 homebanner position-relative">
          <div className="topbanner_flwr">
            <img
              src="assets/img/topbanner_flower.png"
              border={0}
              alt="Top Banner Flower"
            />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="bannertxt f_lightyellow pt-50 pb-50">
                  <h2 className="mb-25 f_lightyellow fw_pjs500">
                    Making your life easier.
                  </h2>
                  <h2 className="mb-25 f_lightyellow fw_pjs500">
                    Never miss to congratulate or celebrate another event.
                  </h2>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="topbanner_img w-100 d-inline-flex justify-content-end pt-30">
                  <img
                    src="assets/img/topbanner_img.png"
                    border={0}
                    alt="Top Banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* shop area end */}
        <section>
          <div className="container">
            <div className="col-md-12">
              <div className="content w-100 mt-50 mb-50">
                <h4 className="text-center mb-50 f_darkgreen">
                  Go ahead and choose all the date(s) on the below calendar of
                  all events worthy of your wishes/blessings
                </h4>
                <div className="calendar-container">
                  <div className="calendar">
                    <div className="year-header">
                      <span
                        className="left-button fa fa-chevron-left"
                        id="prev"
                      >
                        {" "}
                      </span>
                      <span className="year" id="label"></span>
                      <span
                        className="right-button fa fa-chevron-right"
                        id="next"
                      >
                        {" "}
                      </span>
                    </div>
                    <table className="months-table w-100">
                      <tbody>
                        <tr className="months-row">
                          <td className="month">Jan</td>
                          <td className="month">Feb</td>
                          <td className="month">Mar</td>
                          <td className="month">Apr</td>
                          <td className="month">May</td>
                          <td className="month">Jun</td>
                          <td className="month">Jul</td>
                          <td className="month">Aug</td>
                          <td className="month">Sep</td>
                          <td className="month">Oct</td>
                          <td className="month">Nov</td>
                          <td className="month">Dec</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="days-table w-100">
                      <tbody>
                        <tr>
                          <td className="day">Sun</td>
                          <td className="day">Mon</td>
                          <td className="day">Tue</td>
                          <td className="day">Wed</td>
                          <td className="day">Thu</td>
                          <td className="day">Fri</td>
                          <td className="day">Sat</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="frame">
                      <table className="dates-table w-100">
                        <tbody className="tbody"></tbody>
                      </table>
                    </div>
                    {user_id ? (
                      <div className="w-100 d-flex justify-content-center mt-15 mb-25">
                        <a
                          className="btn btn-yellow m-2"
                          id="add-button"
                          onClick={() => retrieveEventData()}
                        >
                          Save and Choose Another Date
                        </a>
                      </div>
                    ) : (
                      <div className="w-100 d-flex justify-content-center mt-15 mb-25">
                        <a
                          href={`/v3/login`}
                          className="btn btn-yellow m-2"
                          id="add-button"
                        >
                          Login
                        </a>
                      </div>
                    )}
                    {eventdata.length !== 0 ? (
                      <div>
                        <div className="flexdiv chosendates_dsply">
                          <h5 className="text-center w-100 mt-25 mb-25">
                            Confirm your chosen dates below to proceed to event
                          </h5>
                          <div className="chosendates_div">
                            {eventdata.map((data) => (
                              <div
                                key={data.id}
                                className="chosendates_listitem"
                              >
                                {`${data.day} ${getShortMonthName(
                                  data.month
                                )} ${data.year}`}
                                <a
                                  className="crsr_pntr remove-button" // Use class instead of id
                                  data-id={data.id}
                                  onClick={() => {
                                    // Call the remove event function directly
                                    removeEvent(data.id);
                                  }}
                                >
                                  <div className="chosendate_close">
                                    <i className="fa-solid fa-xmark"></i>
                                  </div>
                                </a>
                              </div>
                            ))}
                          </div>

                          <div className="flexdiv justify-content-center mt-25 mb-25">
                            <a
                              href="#"
                              className="btn btn-dgreen"
                              onClick={EventCreate}
                              disabled={isButtonDisabled}
                            >
                              Proceed To Event
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
}

export default Homev3;
