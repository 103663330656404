import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Event() {
  const [eventlist, setEventlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  useEffect(() => {
    const pathname = location.pathname.startsWith("/v3");
    if (pathname) {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_APIURL}event/list?user_id=${userId}`)
      .then((res) => {
        if (res.data.status === "success") {
          setEventlist(res.data.result);
        } else {
          // toast.error("Failed to fetch events.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        // toast.error("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <div>Loading...</div>; // Add a loading indicator
  }
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
  };
  return (
    <>
      <main>
        {/* login area start */}
        <section className="tp-login-area pt-120 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="tp-login-wrapper position-relative">
                  <div className="row mb-30 align-items-center">
                    <div className="col-7 col-sm-7 col-lg-7 col-xl-7">
                      <h3 className="tp-login-title">Event(s)</h3>
                    </div>
                    <div className="col-5 col-sm-5 col-lg-5 col-xl-5">
                      <div className="txtalgn_rgt">
                        <a
                          href="/v3"
                          className="f_sz14 f_darkyellow crsr_pntr txt_underline"
                        >
                          Go To Calendar
                        </a>
                      </div>
                    </div>
                  </div>
                  {eventlist.length == 0 ?
                  <div className="row mb-30 align-items-center">
                    <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                      <div className="w-100 d-flex flex-row justify-content-center flex-wrap mt-25 f_sz16">
                        Please choose dates to add the event.
                      </div>
                      <div className="w-100 d-flex justify-content-center mt-15">
                        <a href="/v3" className="btn btn-yellow  m-2">
                          Go To Calendar
                        </a>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="events_dashboard_wrapper row mt-45">
                    {eventlist.map((data) => (
                      <div
                        className="col-12 col-md-12 col-lg-3 col-xl-3"
                        key={data._id}
                      >
                        <div className="events_item mb-15">
                          <div className="events_dash_date">
                            {formatDate(data.event_date)}
                          </div>
                          <div className="events_dash_action">
                            {!data.event_name || !data.event_type ? (
                              <a href={`/v3/add_event?id=${data._id}`}>
                                <i
                                  className="fa-solid fa-circle-plus"
                                  title="Add"
                                />
                              </a>
                            ) : (
                              <a href={`/v3/view_event?id=${data._id}`}>
                                <i className="fa-solid fa-eye" title="View" />
                              </a>
                            )}
                            {(data.event_name || data.event_type) && (
                              <a href={`/v3/edit_event?id=${data._id}`}>
                                <i className="fa-solid fa-pen" title="Edit" />
                              </a>
                            )}
                            <a href="#">
                              <i
                                className="fa-solid fa-trash-can"
                                title="Delete"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>}
                  {eventlist.length > 0 ? (
                    <div className="w-100 d-flex justify-content-center mt-35">
                      <a href={`/v3/checkout`} className="btn btn-yellow m-2">
                        Proceed To Checkout
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* login area end */}
      </main>
      <ToastContainer />
    </>
  );
}

export default Event;
