import React, { useState,useEffect } from "react";
import { useNavigate,useLocation} from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from './../signup';

function SigninV3() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => { 
    const pathname = location.pathname.startsWith('/v3');
    if (pathname) {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");
    }
  }, []);
  
  const handleSignIn = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      toast.error("Email is required");
      return;
    }
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return;
    }

    if (!password.trim()) {
      toast.error("Password is required");
      return;
    }
    // Password validation (minimum length)
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    // API Call
    axios
      .post(`${process.env.REACT_APP_APIURL}newuser/login`, {
        email_address: email,
        password,
      })
      .then((res) => {
        if (res.data.status === "success") {
          window.localStorage.setItem("v3_email_address", email);
          window.localStorage.setItem("v3_user_id", res.data.result._id);
          window.localStorage.setItem("v3_first_name", res.data.result.first_name);
          toast.success("User logged in successfully.");
          window.location.href = '/v3';
          // setTimeout(() => {
          //   navigate("/requestlist");
          // }, 2000); //
           // Redirect to dashboard or appropriate page
        } else {
          if (res.data.error && res.data.error.message) {
            toast.error(res.data.error.message);
          } else {
            toast.error("Invalid login credentials, please try with a valid credential");
          }
        }
      })
      .catch((err) => {
        console.error("Login error:", err.response.data.error.message);
        toast.error(err.response.data.error.message);
      });
  };

  return (
    <main>
      <ToastContainer />
      <section className="tp-login-area pt-150 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-login-wrapper position-relative">
                <div className="tp-login-top text-center mb-30">
                  <h3 className="tp-login-title">Login</h3>
                  <p>
                    Don't have an account?{" "}
                    <span>
                      <a href="/v3/register" className="f_darkyellow">
                      Sign Up
                      </a>
                    </span>
                  </p>
                </div>
                <div className="tp-login-option">
                  <form onSubmit={handleSignIn}>
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="tp_password"
                            type={showPassword ? "text" : "password"} // Toggle input type
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div
                          className="tp-login-input-eye"
                          id="password-show-toggle"
                          onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                          style={{ cursor: "pointer" }}
                        >
                          <span id="open-eye" className={`open-eye ${showPassword ? "d-none" : ""}`}>
                            <i className="fa-solid fa-eye" />
                          </span>
                          <span id="close-eye" className={`open-close ${showPassword ? "" : "d-none"}`}>
                            <i className="fa-solid fa-eye-slash" />
                          </span>
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">Password</label>
                        </div>
                      </div>
                    </div>
                    <div className="tp-login-suggestions d-sm-flex align-items-center justify-content-end mb-20">
                      <div className="tp-login-forgot">
                        <a href="/v3/forgot_password" className="f_darkyellow">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <div className="tp-login-bottom">
                      <button type="submit" className="btn btn-yellow w-100">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SigninV3;
