import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RequestList() {
  const [requestlist, setRequestlist] = useState([]);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [photo_list, setModalMultiphoto] = useState([]);
  const [request_type, setRequestType] = useState("");
  const [frame_type, setFrametype] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(process.env.REACT_APP_APIURL + "request/frame/list?user_id="+userId)
      .then((res) => {
        if (res.data.status === "success") {
          setRequestlist(res.data.result);
        }
      })
      .catch((err) => {
        console.error("Error fetching requests:", err);
      });
  };
  const openMultiModal = (imageUrl) => {
    setModalMultiphoto(imageUrl.photo_list);
    setRequestType(imageUrl.request_type);
    setFrametype(imageUrl.request_type==="frame"?imageUrl.frame_type:imageUrl.album_type);
  };
  const formatFileName = (fileName) => {
    const extension = fileName.split(".").pop();
    let baseName = fileName;

    if (fileName.length > 10) {
      baseName = fileName.substring(0, 6);
      return `${baseName}...${extension}`;
    } else {
      return fileName;
    }
  };
  
  return (
    <>
      <main>
        <section className="tp-login-area pt-120 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="tp-login-wrapper position-relative">
                  <div className="tp-login-top text-center mb-30">
                    <h3 className="tp-login-title">My Request(s)</h3>
                    <div className="w-100 mt-4">
                      <a href="/request" className="btn btn-dark">
                        Album or Frame Request
                      </a>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      id="requestlist"
                      className="table table-striped"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th align="left">S.No</th>
                          <th>Album/Frame</th>
                          <th>No of Photos</th>
                          <th>Type</th>
                          <th>Size</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requestlist.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.request_type}</td>
                            <td>{data.photo_list.length}</td>
                            <td>
                              {data.request_type === "frame"
                                ? data.frame_type
                                : data.album_type}
                            </td>
                            <td>
                              {data.request_type === "frame"
                                ? data.frame_size
                                : data.album_size}
                            </td>
                            <td align="center">
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#modalviewimage"
                                className="crsr_pntr"
                                onClick={() =>
                                    openMultiModal(
                                      data
                                        
                                    )
                                  }
                              >
                                <i className="fa-solid fa-eye m-1" />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        className="modal fade"
        id="modalviewimage"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalviewimageLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalviewimageLabel">
              {request_type.charAt(0).toUpperCase() + request_type.slice(1)} - {frame_type} ({photo_list.length})
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body photosview_div">
              <div className="row">
                {photo_list.map((rows, metaindex) => (
                <div className="col-6 col-sm-3 col-md-3" key={metaindex}>
                  <div className="modalviewimages_wrapper text-center mb-3">
                  <div className="storeimg">
                    <img src={rows.file_url} alt={rows.display_name} />
                  </div>
                  <div className="w-100 text-center">{formatFileName(rows.display_name)}</div>
                  </div>
                </div>
                 ))}
                {/* Add more images here as needed */}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default RequestList;
