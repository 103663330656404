import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();

  // State for form inputs
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Extracting user_id from URL query parameters
  const user_id = new URLSearchParams(location.search).get("user_id");

  const handleResetPassword = (e) => {
    e.preventDefault();

    // Basic validation
    if (!password.trim() || !confirmPassword.trim()) {
      toast.error("Please enter both new password and confirm password");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    // API Call to reset password
    axios
      .post(`${process.env.REACT_APP_APIURL}user/password/reset`, {
        user_id: user_id,
        password: password,
      })
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("Password updated successfully");
          // Redirect to login or appropriate page after success
          navigate("/login");
        } else {
          if (res.data.error && res.data.error.message) {
            toast.error(res.data.error.message);
          } else {
            toast.error("An error occurred during password reset");
          }
        }
      })
      .catch((err) => {
        console.error("Password reset error:", err.response.data.error.message);
        toast.error(err.response.data.error.message);
      });
  };

  return (
    <main>
      <ToastContainer />
      <section className="tp-login-area pt-150 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-login-wrapper position-relative">
                <div className="tp-login-top text-center mb-30">
                  <h3 className="tp-login-title">Reset Password</h3>
                </div>
                <form onSubmit={handleResetPassword}>
                  <div className="tp-login-option">
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div
                          className="tp-login-input-eye"
                          id="password-show-toggle"
                        >
                          <span id="open-eye" className="open-eye">
                            <i className="fa-solid fa-eye" />
                          </span>
                          <span id="close-eye" className="open-close">
                            <i className="fa-solid fa-eye-slash" />
                          </span>
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">New Password</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div
                          className="tp-login-input-eye"
                          id="password-show-toggle"
                        >
                          <span id="open-eye" className="open-eye">
                            <i className="fa-solid fa-eye" />
                          </span>
                          <span id="close-eye" className="open-close">
                            <i className="fa-solid fa-eye-slash" />
                          </span>
                        </div>

                        <div className="tp-login-input-title">
                          <label htmlFor="tp_confirm_password">
                            Confirm New Password
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="tp-login-bottom">
                      <button type="submit" className="btn btn-primary w-100">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ResetPassword;
