import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imageCompression from "browser-image-compression";

function Request() {
  const [requestType, setRequestType] = useState("frame");
  const [frameType, setFrameType] = useState("");
  const [albumType, setAlbumType] = useState("");
  const [frameSize, setFrameSize] = useState("");
  const [albumSize, setAlbumSize] = useState("");
  const [photoList, setPhotoList] = useState([]);
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [uploadProgress, setUploadProgress] = useState({});
  const [overallProgress, setOverallProgress] = useState(0);
  const navigate = useNavigate();

  const validateForm = () => {
    if (!userId) {
      toast.error("User ID is required.");
      return false;
    }
    if (requestType === "frame" && !frameType) {
      toast.error("Frame type is required.");
      return false;
    }
    if (requestType === "frame" && !frameSize) {
      toast.error("Frame size is required.");
      return false;
    }
    if (requestType === "album" && !albumType) {
      toast.error("Album type is required.");
      return false;
    }
    if (requestType === "album" && !albumSize) {
      toast.error("Album size is required.");
      return false;
    }
    if (photoList.length === 0) {
      toast.error("At least one photo must be uploaded.");
      return false;
    }
    return true;
  };

  const submitRequest = () => {
    if (!validateForm()) return;

    const photoIds = photoList.map((photo) => photo._id);
    axios
      .post(process.env.REACT_APP_APIURL + "request/frame/create", {
        request_type: requestType,
        frame_type: requestType === "frame" ? frameType : "",
        frame_size: requestType === "frame" ? frameSize : "",
        album_type: requestType === "album" ? albumType : "",
        album_size: requestType === "album" ? albumSize : "",
        photo_list: photoIds.join(","),
        user_id: userId,
      })
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("Request submitted successfully!");
          navigate("/requestlist"); // Navigate to a success page if needed
        }
      })
      .catch((err) => {
        console.error("Error submitting request:", err);
        toast.error("Failed to submit request!");
      });
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    let loaded = 0;
    let totalFilesSize = Array.from(files).reduce(
      (acc, file) => acc + file.size,
      0
    );

    const promises = Array.from(files).map(async (file) => {
      if (file.size > 10 * 1024 * 1024) {
        const options = {
          maxSizeMB: 10,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        file = await imageCompression(file, options);
      }

      const formData = new FormData();
      formData.append("file", file);

      return axios
        .post(process.env.REACT_APP_APIURL + "file/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: percentCompleted,
            }));
            loaded += progressEvent.loaded;
            setOverallProgress(Math.round((loaded / totalFilesSize) * 100));
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setPhotoList((prevList) => [...prevList, res.data.result.files[0]]);
            return res.data.result.files[0]._id;
          } else {
            throw new Error("File upload failed");
          }
        });
    });

    Promise.all(promises)
      .then(() => {
        // Ensure progress is set to 100% after all uploads are complete
        setOverallProgress(100);
        setUploadProgress((prevProgress) => {
          const updatedProgress = {};
          Object.keys(prevProgress).forEach((key) => {
            updatedProgress[key] = 100;
          });
          return updatedProgress;
        });
        // toast.success("Files uploaded successfully!");
      })
      .catch((err) => {
        console.error("Error uploading files:", err);
        toast.error("Failed to upload files!");
      });
  };

  const handleRemovePhoto = (photoId) => {
    setPhotoList((prevList) =>
      prevList.filter((photo) => photo._id !== photoId)
    );
    setUploadProgress((prevProgress) => {
      const updatedProgress = { ...prevProgress };
      delete updatedProgress[photoId];
      return updatedProgress;
    });
  };

  return (
    <>
      <ToastContainer />
      <main>
        <section className="tp-login-area pt-120 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-8 col-xl-8">
                <div className="tp-login-wrapper position-relative">
                  <div className="tp-login-top text-center mb-30">
                    <h3 className="tp-login-title">My Request</h3>
                  </div>
                  <div className="w-100">
                    <div className="mb-3 row">
                      <label className="col-12 col-sm-12 col-md-4 col-form-label mt-2">
                        Request for
                      </label>
                      <div className="col-12 col-sm-12 col-md-8">
                        <select
                          className="form-select"
                          onChange={(e) => setRequestType(e.target.value)}
                        >
                          <option value="frame">Frame</option>
                          <option value="album">Album</option>
                        </select>
                      </div>
                    </div>
                    {requestType === "frame" && (
                      <>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label mt-2">
                            Choose Frame
                          </label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <select
                              className="form-select"
                              onChange={(e) => setFrameType(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="Canvas Prints">
                                Canvas Prints
                              </option>
                              <option value="Acrylic Photo Frame">
                                Acrylic Photo Frame
                              </option>
                              <option value="Premium Photo with Frame">
                                Premium Photo with Frame
                              </option>
                              <option value="Acrylic Photo Blocks">
                                Acrylic Photo Blocks
                              </option>
                              <option value="Desk Calendar with Photo Frame">
                                Desk Calendar with Photo Frame
                              </option>
                              <option value="LED Photo Frames">
                                LED Photo Frames
                              </option>
                              <option value="LED Rotating Cube">
                                LED Rotating Cube
                              </option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>

                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label mt-2">
                            Frame Size
                          </label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <select
                              className="form-select"
                              onChange={(e) => setFrameSize(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="A4 - 8.3 x 11.7 inches">A4 - 8.3 x 11.7 inches</option>
                              <option value="A5 - 5.8 x 8.3 inches">A5 - 5.8 x 8.3 inches</option>
                              <option value="10 X 10 inches">10 X 10 inches</option>
                              <option value="15 x 30 inches">15 x 30 inches</option>
                              {/* <option value="...">...</option> */}
                            </select>
                          </div>
                        </div>
                      </>
                    )}

                    {requestType === "album" && (
                      <>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label mt-2">
                            Choose Album
                          </label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <select
                              className="form-select"
                              onChange={(e) => setAlbumType(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="Softcover Photobook Album">
                                Softcover Photobook Album
                              </option>
                              <option value="Debossed Hardcover Photobook">
                                Debossed Hardcover Photobook
                              </option>
                              <option value="Layflat Photobook Album">
                                Layflat Photobook Album
                              </option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-12 col-sm-12 col-md-4 col-form-label mt-2">
                            Album Size
                          </label>
                          <div className="col-12 col-sm-12 col-md-8">
                            <select
                              className="form-select"
                              onChange={(e) => setAlbumSize(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="11x14 inches - Landscape">
                                11x14 inches - Landscape
                              </option>
                              <option value="12x16 inches - Landscape">
                                12x16 inches - Landscape
                              </option>
                              <option value="11x8 inches - Portrait">
                                11x8 inches - Portrait
                              </option>
                              <option value="14x11 inches - Portrait">
                                14x11 inches - Portrait
                              </option>
                              {/* <option value="...">...</option> */}
                            </select>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="mb-3 row">
                      <label className="col-12 col-sm-12 col-md-4 col-form-label mt-2">
                        Upload Photo(s)
                      </label>
                      <div className="col-12 col-sm-12 col-md-8">
                        <div
                          className="position-relative mt-2"
                          style={{ display: "block" }}
                        >
                          <label className="btn-bs-file btn btn-dark">
                            Browse
                            <input
                              type="file"
                              multiple
                              accept=".png, .jpg, .jpeg"
                              onChange={handleFileUpload}
                            />
                          </label>
                        </div>
                        <div className="flexdiv mt-2">
                          <div className="f_bold">({photoList.length})</div>
                          <div className="w-100 mt-2">
                            <div className="progress">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: `${overallProgress}%` }}
                                aria-valuenow={overallProgress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              >
                                {overallProgress}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {photoList.map((photo) => (
                        <div
                          className="col-12 col-sm-12 col-md-6"
                          key={photo._id}
                        >
                          <div className="upld_photoitem">
                            <table width="100%" cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td width={100} align="center">
                                    <div className="upld_photo">
                                      <img
                                        src={photo.file_url}
                                        alt="Uploaded"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="progress">
                                      <div
                                        className="progress-bar bg-success"
                                        role="progressbar"
                                        style={{ width: `100%` }}
                                        aria-valuenow={
                                          uploadProgress[photo.file_name] || 0
                                        }
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      >
                                        {100}%
                                      </div>
                                    </div>
                                  </td>
                                  <td width={30} align="center">
                                    <a
                                      href="#"
                                      onClick={() =>
                                        handleRemovePhoto(photo._id)
                                      }
                                    >
                                      <i className="fa-regular fa-trash-can f_18 f_red" />
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-primary mt-3"
                        onClick={submitRequest}
                      >
                        Submit Request
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Request;
