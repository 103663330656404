import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Checkout() {
  const [searchParams] = useSearchParams();
  const [eventlist, setEventList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("v3_user_id");
  const EventId = searchParams.get("id");

  useEffect(() => {
    const pathname = location.pathname.startsWith("/v3");
    if (pathname) {
      document.body.classList.add("bgyellow");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);

    const url = `${process.env.REACT_APP_APIURL}event/list?user_id=${userId}&checkoutlist=true`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setEventList(res.data.result);
        } else {
          toast.error("Failed to fetch events.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        toast.error("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
  };

  const handleRemoveEvent = (index) => {
    const updatedEventList = eventlist.filter((_, i) => i !== index);
    setEventList(updatedEventList);
    toast.success("Event removed from checkout.");
  };

  const eventsum = eventlist.reduce((sum, event) => sum + event.total_price, 0);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <main>
        {/* login area start */}
        <section className="tp-login-area pt-120 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="tp-login-wrapper position-relative">
                  <div className="row mb-30 align-items-center">
                    <div className="col-7 col-sm-7 col-lg-7 col-xl-7">
                      <h3 className="tp-login-title">Checkout</h3>
                    </div>
                    <div className="col-5 col-sm-5 col-lg-5 col-xl-5">
                      <div className="txtalgn_rgt">
                        <a
                          href="/v3/event"
                          className="f_sz14 f_darkyellow crsr_pntr txt_underline"
                        >
                          Back
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                      <div className="table-responsive">
                        <table
                          width="100%"
                          cellPadding={0}
                          cellSpacing={0}
                          className="table table-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th style={{ minWidth: 50 }} className="text-center">
                                S.No
                              </th>
                              <th style={{ minWidth: 600 }} className="text-center">
                                Details
                              </th>
                              <th style={{ minWidth: 250 }} className="text-center">
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {eventlist.map((data, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="txtdsply f_sz14 mb-5">
                                    <i className="fa-solid fa-calendar-days f_darkyellow mr-10" />
                                    <span className="fw_pjs700 f_darkgreen f_sz16">
                                      {data.event_date ? formatDate(data.event_date) : "N/A"}
                                    </span>
                                  </div>
                                  <div className="txtdsply f_sz14 mb-5">
                                    <i className="fa-solid fa-user f_darkyellow mr-10" />
                                    <span className="fw_pjs700">{data.event_name}</span>
                                  </div>
                                  <div className="txtdsply f_sz14 mb-5">
                                    <i className="fa-solid fa-calendar-check f_darkyellow mr-10" />
                                    {data.event_type}
                                  </div>
                                  <div className="txtdsply f_sz14 mb-10">
                                    <i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                                    {data.address}
                                  </div>
                                  <div className="brdr_btm w-100 mt-10 mb-10" />
                                  <div className="flexdiv">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                      <div className="fw_pjs700">Greeting Card</div>
                                      <div className="txtdsply f_sz14 mb-10">
                                        <i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                                        {data.greeting_notes}
                                      </div>
                                      {data.greeting_card_info && (
                                        <div className="greetingcardgift_wrapper">
                                          <div className="greetingcardgift_item">
                                            <div className="greetingcardgift_img_sm">
                                              <img
                                                src={data.greeting_card_info[0].url}
                                                alt="Greeting Card"
                                              />
                                            </div>
                                            <div className="greetingcardgift_price">
                                              Rs. {data.greeting_card_info[0].price}.00
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {data.gift_info && data.gift_info.map((giftData, giftIndex) => (
                                      <div key={giftIndex} className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="fw_pjs700">Gift</div>
                                        <div className="greetingcardgift_wrapper">
                                          <div className="greetingcardgift_item">
                                            <div className="greetingcardgift_img_sm">
                                              <img
                                                src={giftData.url}
                                                alt={`Gift ${giftIndex + 1}`}
                                              />
                                            </div>
                                            <div className="greetingcardgift_price">
                                              Rs. {giftData.price}.00
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="brdr_btm w-100 mt-10 mb-10" />
                                  <div className="w-100 d-flex flex-row flex-wrap">
                                    <div>
                                      <button
                                        className="btn btn-yellow btn-sm m-2"
                                        onClick={() => handleRemoveEvent(index)}
                                      >
                                        Remove From Checkout
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td className="txtalgn_rgt fw_pjs700 f_sz16">
                                  Rs. {data.total_price}.00
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={2} className="txtalgn_rgt fw_pjs700">
                                <div className="f_sz18">Subtotal</div>
                                <div className="f_sz18 mt-10">Shipping</div>
                                <div className="f_sz18 mt-10">Tax</div>
                                <div className="f_sz22 mt-10">
                                  Total for sending greeting card and gift{" "}
                                </div>
                              </td>
                              <td className="txtalgn_rgt fw_pjs700">
                                <div className="f_sz18">Rs. {eventsum}.00</div>
                                <div className="f_sz18 mt-10">Rs. 0</div>
                                <div className="f_sz18 mt-10">Rs. 0</div>
                                <div className="f_sz22 mt-10"> Rs. {eventsum}.00 </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="w-100 d-flex flex-row flex-wrap">
                      <span className="fw_pjs700">Note: </span>You cannot make changes to your order once order is placed and paid.
                    </div>
                    <div className="w-100 d-flex flex-row flex-wrap text-center justify-content-center mt-55">
                      <div>
                        <a
                          // href=""
                          className="btn btn-dgreen btnpdng_md m-2"
                        >
                          Pay
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* login area end */}
      </main>

      <ToastContainer />
    </>
  );
}

export default Checkout;
